import {Footer} from '@genomicsplc/denim-components';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Routes} from '../../Routes';

export function LegalFooter(props) {
    const {className} = props;

    const footerClasses = classNames('legal-footer', className);

    return (
        <Footer
            className={footerClasses}
            role={'contentinfo'}
        >
            <div className={'grid'}>
                <div
                    className={'legalese is-caption is-second'}
                    data-test-id={'legalese-footer'}
                >
                    <span className={'copyright'}>
                        <FormattedMessage id={'footer.copyright'} values={{currentYear: new Date().getFullYear()}} />
                    </span>
                    <Link
                        className={'link'}
                        data-test-id={'terms-and-conditions-link'}
                        to={Routes.TERMS_AND_CONDITIONS}
                    >
                        <span>
                            <FormattedMessage id={'footer.terms-and-conditions'} />
                        </span>
                    </Link>
                    <Link
                        className={'link'}
                        data-test-id={'privacy-policy-link'}
                        to={Routes.PRIVACY_POLICY}
                    >
                        <span>
                            <FormattedMessage id={'footer.privacy-policy'} />
                        </span>
                    </Link>
                </div>
            </div>
        </Footer>
    );
}
